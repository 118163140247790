import React, { useEffect, useState, useContext } from "react"
import ProductCard from "./ProductCard"
import { getProductsByShopId } from "../../../../api/public/products"
import SparkleMenuGif from "../../../../assets/images/sparkles-gif.gif"
import { ShowBubbleContext } from "../../../globalstates"

import { getAllFavoriteProducts } from "../../helpers"

import { withDeferRender } from "react-defer-renderer"

import sortArrayByNumberOfElements from "../../../../helpers/sortArrayByNumberOfElements"

import { FixedSizeList as List } from "react-window"


const Preloader = () => {
  return <img src={SparkleMenuGif} className="menu-preloader" />
}

const Menu = (props) => {
  const [products, setProducts] = useState([])
  const [favorites, setFavorites] = useState(getAllFavoriteProducts())
  const [showBubbleState, setShowBubbleState] = useContext(ShowBubbleContext)

  useEffect(() => {
    getProductsByShopId(props.shopId).then((response) => {
      let sortedProducts = sortArrayByNumberOfElements(response, 2)
      console.log(sortedProducts)
      setProducts(sortedProducts)
      setShowBubbleState(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.shopId])

  const ProductRow = ({ index, style }) => {
    let productRow = products[index]

    return <div style={style}> <div style={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around'
    }}>

      {productRow[0] ? <ProductCard
        viewMode={props.viewMode}
        addToBag={props.addToBag}
        data={productRow[0]}
        shopSchedule={props.shopSchedule}
        favorites={favorites}
        setFavorites={setFavorites}
        key={productRow[0]._id}
        to={props.to}
      /> : null}


      {productRow[1] ? <ProductCard
        viewMode={props.viewMode}
        addToBag={props.addToBag}
        data={productRow[1]}
        shopSchedule={props.shopSchedule}
        favorites={favorites}
        setFavorites={setFavorites}
        key={productRow[1]._id}
        to={props.to}
      /> : null}

    </div>
    </div>

  }

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center'
    }}>
      {products.length > 0 ? (
        <List height={800} width={375} itemCount={products.length} itemSize={230}>
          {ProductRow}
        </List>
      ) : (
        <Preloader />
      )}
    </div>
  )
}

export default withDeferRender(Menu)
